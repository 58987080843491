<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div class="h-handle-button" v-show="!config.isDetailDisplay" slot="buttonGroup">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Productbom:Empty'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="del" v-right-code="'Productbom:Remove'">删除</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="enable" v-right-code="'Productbom:Disable'">启用</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="disable" v-right-code="'Productbom:Disable'">停用</el-button>
                </div>
            </div>
            <el-table slot="elList" ref="userListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                <el-table-column type="selection" width="33" v-if="!config.isDetailDisplay"></el-table-column>
                <el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams"
                                 :key="index"
                                 :prop="col.FieldName"
                                 :label="col.DisplayName"
                                 :render-header="bindFilter(queryParam,col)"
                                 :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                 :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                 v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                    <template slot-scope="scope">
                        <span v-if="col.FieldName==='ProductCombineNo'"><a @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</a></span>
                        <span v-else-if="col.FieldName==='SkuStatusStr'" width="100%">
                            <el-tag :type="scope.row['ProductStatus'] == 100 ? 'success' : 'danger'">{{ scope.row[col.FieldName] }}</el-tag>
                        </span>
                        <span v-else>  {{ scope.row[col.FieldName] }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                this.Event.$on("t.bom.onSave", () => this.reloadPageList());
                this.Event.$on("t.bom.onAdd", () => this.add());
            });
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    params: {}
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            selectCondition: {
            }
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize: function () {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            onPageChange: function (param) {
                this.queryParam = param;
                if (this.selectCondition) {
                    this.Utils.extend(this.queryParam.params, this.selectCondition);
                }
                this.$ajax.query("omsapi/productbom/getall", "post", param, data => {
                    this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick: function (row) {
                this.$ajax.send("omsapi/productbom/search", "get", { CombineID: row.CombineID }, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add: function () {
                this.$ajax.send("omsapi/productbom/empty", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            del: function () {
                if (this.multipleSelection.length == 0) {
                    this.Utils.messageBox("未选择删除的组合货品.");
                    return;
                }

                var errorMsg = '';
                this.multipleSelection.forEach(item => {
                    switch (item.ProductStatus) {
                        case 100:
                        case 200:
                            errorMsg = '非草稿状态的组合货品不能删除.';
                            break;
                    }
                });

                if (errorMsg.length > 0) {
                    this.Utils.messageBox(errorMsg);
                    return;
                }

                var This = this;
                this.Utils.confirm({
                    title: "是否确认删除?",
                    content: "删除后数据不可恢复,是否确认删除?"
                }, () => {
                    var ids = this.multipleSelection.map(item => item.CombineID);
                    this.$ajax.send("omsapi/productbom/remove", "post", ids, (data) => {
                        this.initialize();
                        this.Utils.messageBox("删除成功.", "success");
                    });
                });
            },
            enable: function () {
                if (this.multipleSelection.length == 0) {
                    this.Utils.messageBox("未选择启用的组合货品.");
                    return;
                }

                var errorMsg = '';
                this.multipleSelection.forEach(item => {
                    switch (item.ProductStatus) {
                        case 100:
                            errorMsg = '已启用状态的组合货品不能再启用.';
                            break;
                    }
                });
                if (errorMsg.length > 0) {
                    this.Utils.messageBox(errorMsg);
                    return;
                }

                this.multipleSelection.forEach(item => item.Enable = true);
                var ids = this.multipleSelection.map(item => item.CombineID);
                this.$ajax.send("omsapi/productbom/enable", "post", ids, (data) => {
                    this.Utils.messageBox("启用组合货品完成.", "success");
                    this.initialize();
                });
            },
            disable: function () {
                if (this.multipleSelection.length == 0) {
                    this.Utils.messageBox("未选择停用的组合货品.");
                    return;
                }

                var errorMsg = '';
                this.multipleSelection.forEach(item => {
                    switch (item.ProductStatus) {
                        case 0:
                            errorMsg = '草稿状态的货品未启用,不需要停用.';
                            break;
                        case 200:
                            errorMsg = '停用状态的货品未启用,不需要停用.';
                            break;
                    }
                });
                if (errorMsg.length > 0) {
                    this.Utils.messageBox(errorMsg, "info");
                    return;
                }

                var This = this;
                this.Utils.confirm({
                    title: "是否确认停用选中组合货品?",
                    content: "停用后该组合货品将不可用?"
                }, () => {
                    this.multipleSelection.forEach(item => item.Enable = false);
                    var ids = this.multipleSelection.map(item => item.CombineID);
                    this.$ajax.send("omsapi/productbom/disable", "post", ids, (data) => {
                        this.Utils.messageBox("停用组合货品完成.", "success");
                        this.initialize();
                    });
                });
            },
            onChangeEditDataSource: function (data) {
                this.config.isDetailDisplay = true;
                this.Event.$emit("clearForm");
                this.$emit("onChangeEditDataSource", data);
            }
        },
        components: {
        }
    }
</script>
<style>
</style>